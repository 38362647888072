/*
 * Copyright Max Hecht 18.01.20, 23:00
 */
import $ from 'jquery';
import '../validation/auftrag';
import * as validation from '../validation/core';
import * as auftrag from '../auftrag/auftragspositionen';

let kunde = '#lagerabholung_kunde';
let kommission = '#lagerabholung_bestellnr';
let barverkauf_info = '#barverkauf_info';
let alleArtikelpositionen = '.validate_auftrag_positionen_artikel';
let kundeIsValid = false;
//localstorage.refreshData('kunde');

//const Kunden = function () {
// return JSON.parse(localStorage.getItem('kunde'));};

$(function () {
    auftragInitialisieren();
    kundeValidieren();
    kommissionValidieren();
    barverkauf();
    clickalbe_table_row();

});

function clickalbe_table_row() {
    $('.clickable_table_row').click(function () {
        window.location = $(this).data('href');
    });

}

function auftragInitialisieren() {
    $(barverkauf_info).hide();
    $('#lagerabholung').on('keypress', ':input:not(textarea)', function (event) {
        if (event.keyCode == 13) {
            event.preventDefault();
        }
    });
    $(kunde).focus();

}

function kundeValidieren() {
    $(kunde).keyup(async function (event) {
        kundeIsValid = false;
        if (event.keyCode == 13 || event.keyCode == 9) {
            const Kunde = await $.getJSON('/suche/kunde', {'kundennr': $(kunde).val()});
            if (Kunde) {
                kundeIsValid = true;
                $('#kundenspeicher').val(JSON.stringify(Kunde));
                $('#kunde_anzeigen').html(Kunde.strasse + ', ' + Kunde.plz + ' ' + Kunde.ort);
                $('#lagerabholung_titel').html('Abholung von ' + Kunde.nachnamefirma);
                validation.makeValid(kunde);
                if ($(kommission).val() == '') {
                    $(kommission).focus();
                }
            }
            validation.makeInvalid(kommission);
        }
    });
}

function kommissionValidieren() {

    $(kommission).keyup(function (event) {
        let value = $(kommission).val();
        if (value === '') {
            validation.makeInvalid(kommission);
        } else {
            validation.makeValid(kommission);
        }
        if (event.keyCode == 13 || event.keyCode == 9) {
            if (auftrag.getIndex() == 0) {

                auftrag.addPosition();
            }
        }
    });
}

function l(msg) {
    console.log(msg);
}

function barverkauf() {
    $('#lagerabholung_barverkauf').on('click', function () {
        $(barverkauf_info).toggle();
    });
}

