/*
 * Copyright Max Hecht 07.01.20, 14:10
 */

//'use strict';
import '../css/app.css';
import 'jquery';
import 'bootstrap';
import 'webpack-jquery-ui';
import 'webpack-jquery-ui/css';
import 'jquery-validation';
import 'babel-polyfill';
import 'babel-preset-es2015';
import './auftrag/auftrag';
import './suche/artikel';
import './suche/lagerbestand';
import './suche/kunde'; //import './suche/zahlung';
//import './suche/zahlung';
