/*
 * Copyright Max Hecht 07.01.20, 14:10
 */
export function makeInvalid (identifier)
{
    $(identifier).addClass('is-invalid');
    $(identifier).removeClass('is-valid');
}

export function makeValid (identifier)
{
    $(identifier).addClass('is-valid');
    $(identifier).removeClass('is-invalid');
}

