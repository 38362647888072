/*
 * Copyright Max Hecht 07.01.20, 14:10
 */
import $ from 'jquery';

$(function () {
    $('#lagerabholung_form').validate({
        rules   : {
            'lagerabholung[kommission]': {
                required: true,
            },
        },
        messages: {
            'lagerabholung[kommission]': {
                required: 'Die Kommission wird benötigt.',
            },
        },
    });
});
