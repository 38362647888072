/*
 * Copyright Max Hecht 18.01.20, 23:00
 */
import $ from 'jquery';

$(function () {
    let cache = {};
    $('.js_suche_artikel').keyup(function () {
        let artikel = $(this);
        let suche = artikel.val();
        console.log('Artikelsuche: ' + suche);
        $(this).autocomplete({
            minLength: 2,
            autofocus: true,
            delay    : 150,
            source   : function (request, response) {
                let term = request.term;
                if (term in cache) {
                    response(cache[term]);
                    return;
                }
                $.getJSON('/suche/autocomplete/artikel', {'beschreibung': suche}, function (data) {
                    cache[term] = data;
                    response(data);
                });
            },
        });
    });
});


