import $ from 'jquery';

$(function () {
    let cache = {};
    $('.js_suche_kunden').keydown(function () {
        let suche = $(this).val();
        $('.js_suche_kunden').autocomplete({
            minLength: 3,
            autoFocus: false,
            delay    : 150,
            source   : function (request, response) {
                let term = request.term;
                if (term in cache) {
                    response(cache[term]);
                    return;
                }
                $.getJSON('/suche/kunde', {'name': suche}, function (data) {
                    cache[term] = data;
                    response(data);
                });
            },
        });
    });
});


