/*
 * Copyright Max Hecht 18.01.20, 23:00
 */

import $ from 'jquery';
import * as validation from '../validation/core';

let auftragspositionen;
let kunde;
let gesamtpreis = 0;
let index = 0;
let allePositionenValid = false;

auftragspositionen = $('ol.auftrag_artikelpositionen');
auftragspositionen.data('index', auftragspositionen.find(':input').length);

export function addPosition() {
    let prototype = auftragspositionen.data('prototype');
    let newPosition = prototype;
    index = auftragspositionen.data('index');
    newPosition = newPosition.replace(/__name__/g, index);
    auftragspositionen.data('index', index + 1);
    auftragspositionen.append(newPosition);
    auftragspositionen.last().hide().slideDown(300);
    validateAllePositionen();
    focusArtikelposition(index);
}

function getLeerePosition() {

    let leerePosition = auftragspositionen.data();
    l('POSITION: ' + JSON.stringify(leerePosition));
}

function getKunde() {
    kunde = JSON.parse($('#kundenspeicher').val());
    if (kunde == '') {
        alert('Auf den Kunden kann nicht zugegriffen werden.');
    } else {
        return kunde;
    }
}

function validateMenge(position) {
    let menge = $(getMengeposition(position)).val();

}

export function validateAllePositionen() {
    $('.validate_auftrag_positionen_artikel').keyup(async function (event) {
        let pos = $(this);
        let artikelnr = pos.val();
        let artikelPosId = pos.attr('id');
        l('Artikel ' + artikelnr + ' wird validiert.');
        let Artikel = await $.getJSON('/suche/find/artikel', {'artikelnr': artikelnr});
        if (Object.keys(Artikel).length > 0) {
            l('Artikel ' + artikelnr + ' ist valid.');
            allePositionenValid = true;
            let k = event.keyCode;
            if ((k == 13 || k == 9) && allePositionenValid === true) {
                addPosition();
            }
            validation.makeValid('#' + artikelPosId);
        } else {
            l('Artikel ' + artikelnr + ' ist invalid.');
            allePositionenValid = false;
            validation.makeInvalid('#' + artikelPosId);

        }

    });

}

function short(string, maxLength = 8) {
    const array = string.trim().split(' ');
    const ellipsis = array.length > maxLength ? '...' : '';
    return array.slice(0, maxLength).join(' ') + ellipsis;
}

function gesamtpreisBerechnen(preis) {
    let preisanzeige = $('#auftrag_gesamtpreis_anzeigen');
    gesamtpreis = gesamtpreis + preis;
    gesamtpreis = Math.round(gesamtpreis, 2);
    let anzeige = '<p>Gesamtpreis: <b>' + gesamtpreis + ' €</b></p>';
    preisanzeige.html(anzeige);
    preisanzeige.show();
}

function addPreisInfo(preis) {
    l('Preis' + preis);
    let Preis = preis + ' €';
    getPreisInfoPosition().val(Preis);
}

function focusArtikelposition(position) {
    $(getArtikelposition(position)).focus();
}

function l(msg) {
    console.log(msg);
}

function getArtikelposition(index) {
    return '#lagerabholung_auftragsposition_' + index + '_artikel';
}

function getMengeposition(index) {
    return '#lagerabholung_auftragsposition_' + index + '_menge';
}

export function getIndex() {
    let index = auftragspositionen.data('index');
    return index;
}




